<template>
    <div class="row m-0">
        <!-- @row-click="handleItemClick" 변수 수정 모달 구현 완료시 적용 -->
        <TableList
            ref="table-list"
            :title="'주 변수 정보'"
            :columns="columns"
            :rows="mainPropList"
            :keyField="'idx'"
            :useFilter="false"
            :fixedHeader="false"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="false"
            :headerMargin="false"
            :isSort="false"
            :transCodes="transCodes"
            @button-click="handleListButtonClick"
        />
    </div>
</template>

<script>
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["detailInfo"],
    components: {
        TableList,
    },
    data() {
        return {
            controls: [
                { event: "regist", label: "추가", class: "btn-primary" },
                { event: "delete", label: "삭제", class: "btn-danger" },
            ],
            transCodes: [
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
                {
                    field: "srcType",
                    codes: this.$store.state.commonCodes.srcType,
                },
            ],
            columns: [
                {
                    label: this.$t("변수명"),
                    field: "name",
                    width: "250px",
                    type: "text",
                },
                {
                    label: this.$t("유형"),
                    field: "srcType",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("단위"),
                    field: "unit",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("관제점 주소"),
                    field: "ptAddr",
                    type: "text",
                },
                {
                    label: this.$t("관제점명"),
                    field: "ptName",
                    width: "250px",
                    type: "text",
                },
            ],
            mainPropList: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getMainPropList();
    },
    mounted() {},
    methods: {
        getMainPropList() {
            if (this.detailInfo.analysisMethod == "MNV") {
                this.mainPropList = this.detailInfo.variables.filter((val) => val.roleType == "Depend") ?? [];
            } else this.mainPropList = this.detailInfo.variables.filter((val) => val.roleType == "Main") ?? [];
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;
                case "delete":
                    await this.deleteChecked();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
    },
};
</script>
