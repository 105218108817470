<template>
    <div class="detail-container">
        <div v-if="isLoadingPdf" class="spinner-overlay">
            <b-spinner class="align-middle"> </b-spinner>
        </div>
        <div style="height: 100%">
            <div class="detail-header">
                <div class="col detail-title">
                    <!-- <span class="title">{{ vie/wTitle }}</span> -->
                    <span class="title">{{ "데이터 분석 설정" }}</span>

                    <span class="badge title1">{{ detailInfo.modelName ?? "-" }}</span>
                    <span class="badge title2"> {{ detailInfo.category ?? "-" }} </span>
                    <span class="badge others"> {{ targetPtName ?? "-" }} </span>

                    <span class="badge others">
                        {{ `연관 데이터 수 (${detailInfo.variables?.length ?? "-"}개)` }}
                    </span>
                    <span class="badge others">
                        {{ !isEmpty(detailInfo.publish) ? "메뉴 노출 중" : "메뉴 노출 비활성" }}
                    </span>
                </div>
                <div class="detail-control">
                    <div class="btn-group mr-2">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteAnalysisModel">
                            {{ "삭제" }}
                        </button>
                        <button type="button" class="btn btn-sm btn-white" @click="changeExpandStatus">
                            {{ "목록" }}
                        </button>

                        <button type="button" class="btn btn-sm btn-white" @click="newAnalysisModel">
                            {{ "신규" }}
                        </button>

                        <button type="button" class="btn btn-sm btn-primary" @click="saveData">{{ "저장" }}</button>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: calc(100% - 50px)">
                <div style="width: 100%">
                    <div block v-b-toggle.setting class="accordion-title">
                        <span>{{ "분석 조건 설정" }}</span>
                        <span class="when-opened">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                        <span class="when-closed">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>
                    <b-collapse
                        v-model="settingCollapse"
                        visible
                        ref="setting"
                        id="setting"
                        class="collapse-body setting-itemview"
                        accordion="setting"
                    >
                        <div ref="exportPDF">
                            <SettingPanel
                                ref="SettingPanel"
                                :detailInfo="detailInfo"
                                :analysisList="analysisList"
                                :resultData="resultData"
                                :reportMnvType="reportMnvType"
                                @exportToPDF="exportToPDF"
                                @changeAnalysisWay="changeAnalysisWayFunc"
                                @result="getAnalysisResultData"
                                @clearResult="clearResultData"
                                @save-modal="saveData"
                                @updateAdjustData="handleAdjustData"
                                @updateFixIndependData="handleFixIndependData"
                                @changeGenerateMnvReportType="changeGenerateMnvReportType"
                            />
                        </div>
                    </b-collapse>
                </div>
                <div
                    style="width: 100%;"
                    :style="{ height: settingCollapse ? 'calc(100% - 240px)' : 'calc(100% - 50px)' }"
                >
                    <div block v-b-toggle.analysisResult class="accordion-title">
                        <div>
                            <span class="analysisResultClass">{{ "분석 결과" }}</span>
                            <button
                                v-if="analysisMethod === 'MNV'"
                                @click="handleMnvTabs(0, $event)"
                                :class="['mnvTab', { active: mnvTab === 0 }]"
                                aria-pressed="mnvTab === 0"
                            >
                                분석 데이터
                            </button>
                            <button
                                v-if="analysisMethod === 'MNV'"
                                @click="handleMnvTabs(1, $event)"
                                :class="['mnvTab', { active: mnvTab === 1 }]"
                                aria-pressed="mnvTab === 1"
                            >
                                분석 보고서
                            </button>
                        </div>

                        <span>
                            <span
                                v-if="detailInfo.analysisMethod !== 'MNV'"
                                style="cursor: pointer; margin-right: 1rem;"
                                @click="handleInfoPanel($event)"
                                v-b-tooltip.hover
                                :title="$t(`${hideInfoPanel ? '분석 개요 가리기' : '분석 개요 보기'}`)"
                            >
                                <i :class="hideInfoPanel ? 'fas fa-indent' : 'fas fa-outdent'" aria-hidden="true"></i>
                            </span>
                            <span
                                v-if="
                                    detailInfo.analysisMethod === 'Trend' ||
                                        detailInfo.analysisMethod === 'Diff' ||
                                        detailInfo.analysisMethod === 'Comp'
                                "
                                class="status-icon pr-3"
                                style="cursor: pointer"
                                @click="showStatisticModal($event)"
                                v-b-tooltip.hover
                                :title="$t('통계 항목 표시 제어')"
                            >
                                <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
                            </span>
                            <!-- <span
                                v-if="detailInfo.analysisMethod !== 'MNV'"
                                style="cursor: pointer"
                                v-b-tooltip.hover
                                :title="$t('분석 개요 가리기')"
                            >
                                <i class="fas fa-angle-double-left" aria-hidden="true"></i>
                            </span> -->
                            <span class="when-opened">
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                            </span>
                        </span>
                    </div>
                    <b-collapse
                        visible
                        ref="analysisResult"
                        id="analysisResult"
                        class="collapse-body result-itemview"
                        accordion="analysisResult"
                    >
                        <!-- MNV 숨기기위한 주석. 풀면 분석방법을 mnv로 했을 시 보입니다. 밑에 placeholder를 포함한 태그를 주석처리하면 깔끔하게 보입니다.-->
                        <!-- <div v-if="detailInfo.analysisMethod === 'MNV' && !isEmpty(resultData)" class="w-100 h-100">
                            <mnv-result-panel></mnv-result-panel>
                        </div> -->
                        <div v-if="isLoading" class="analysisSpinner">
                            <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </div>
                        <div v-else-if="apiCallErrorFlag" class="list-placeholder">
                            <span class="detail-notification-icon">
                                <i class="fas fa-exclamation-circle"></i>
                            </span>
                            <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
                        </div>
                        <div v-else-if="!isEmpty(resultData)" ref="result" class="row ">
                            <!-- <div v-if="detailInfo.analysisMethod === 'MNV'" class="w-100 h-100">
                                <mnv-result-panel :detailInfo="detailInfo" :resultData="resultData"></mnv-result-panel>
                            </div> -->
                            <!-- <div v-else class="row"> -->
                            <div class="row">
                                <div class="col-12 " style="display: flex; justify-content: center;">
                                    <h4>{{ detailInfo.modelName }}</h4>
                                </div>
                                <div
                                    :class="
                                        detailInfo.analysisMethod === 'MNV'
                                            ? 'col-12'
                                            : hideInfoPanel
                                            ? 'col-9 pl-0'
                                            : 'col-12'
                                    "
                                >
                                    <ResultChartPanel
                                        :detailInfo="detailInfo"
                                        :chartResize="chartResize"
                                        :analysisMethod="analysisMethod"
                                        :resultData="resultData"
                                        :calendarList="calendarList"
                                        :switchValues="switchValues"
                                        :adjustData="adjustData"
                                        :fixIndependData="fixIndependData"
                                        :mode="'setting'"
                                        :existMnvReportFlag="existMnvReportFlag"
                                        :reportMnvType="reportMnvType"
                                        :hideInfoPanel="hideInfoPanel"
                                        @show-property-management-modal="showPropertyManagementModal"
                                        @changeGenerateMnvReportType="changeGenerateMnvReportType"
                                        @activeTabMnvReport="activeTabMnvReport"
                                        @updateFactor="updateFactor"
                                        :mnvTab="mnvTab"
                                        @update:MnvTab="handleMnvTabs"
                                    >
                                    </ResultChartPanel>
                                </div>
                                <div class="col-3 pr-0" v-if="detailInfo.analysisMethod !== 'MNV' && hideInfoPanel">
                                    <ResultInfoPanel
                                        :detailInfo="detailInfo"
                                        :calendarList="calendarList"
                                        :resultData="resultData"
                                        @detail="showAnalysisHistory"
                                        @getEnergyCalendarList="getEnergyCalendarList"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- <div v-else class="list-placeholder">
                            <span class="list-notification-icon">
                                <i class="fas fa-external-link-alt"></i>
                            </span>
                            <span class="list-notification-text">
                                {{ "분석 조건을 설정해서 분석을 실행해주세요." }}
                            </span>
                        </div> -->
                    </b-collapse>
                </div>
            </div>
        </div>
        <b-modal id="statistic-modal" ref="statisticModal" title="통계 항목 표시 제어">
            <template #modal-header>
                <h5 class="mb-0">통계 항목 표시 제어.</h5>
            </template>
            <div>
                <div
                    v-for="(label, key) in switchLabels"
                    :key="key"
                    class="d-flex align-items-center justify-content-between my-2"
                >
                    <label :for="'switch-' + key">{{ label }}</label>
                    <b-form-checkbox v-model="switchValues[key]" :id="'switch-' + key" switch></b-form-checkbox>
                </div>
            </div>

            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        type="button"
                        class="btn btn-secondary ctrl-modal-btn"
                        @click="closeStatisticModal"
                        style="flex: 1; text-align: center;"
                    >
                        닫기
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import SettingPanel from "./panels/SettingPanel.vue";
import ResultChartPanel from "./panels/ResultChartPanel.vue";

import ResultInfoPanel from "./panels/ResultInfoPanel.vue";
import html2pdf from "html2pdf.js";

import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["analysisList"],
    components: {
        SettingPanel,
        ResultChartPanel,
        ResultInfoPanel,
    },
    data() {
        return {
            mnvTab: 0,
            isLoading: false,
            isLoadingPdf: false,
            detailInfo: null,
            analysisWay: "",
            chartResize: false,
            settingCollapse: true,
            showReltnGoal: false,

            resultData: [],
            analysisMethod: null,

            calendarList: [],
            switchValues: {
                mean: false, // 평균
                quartile: false, // 사분위수
                outlier: false, // 이상
                event: false, // 이벤트
            },
            apiCallErrorFlag: false,
            switchLabels: {
                mean: "평균",
                quartile: "사분위수",
                outlier: "이상",
                event: "이벤트",
            },
            // MNV리포트를 생성하는방식이 분석 준비버튼을 클릭해서 만드는지 리포트생성 버튼을 클릭해서 만드는지 판별하기 위함.
            reportMnvType: null,
            //MNV 보고서 생성을 한 후 저장을 하여 이미 저장된 보고서 유무를 판별하여 탭전환에 사용하기 위함.
            existMnvReportFlag: false,
            //조정값
            adjustData: {},
            fixIndependData: {},
            popupMessages,
            localFactor: {},
            hideInfoPanel: false,
        };
    },
    computed: {
        targetPtName() {
            let find = this.$store.state.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(find) ? find.ptName : null;
        },
    },
    watch: {
        detailInfo: {
            deep: true,
            handler() {
                this.adjustData = {};
            },
        },
        "switchValues.mean": function(newVal) {
            if (newVal) {
                this.switchValues.quartile = false;
            }
        },
        "switchValues.quartile": function(newVal) {
            if (newVal) {
                this.switchValues.mean = false;
            }
        },
        "detailInfo.analysisMethod": {
            deep: true,
            handler(method) {
                if (method === "Goal") {
                    return;
                } else {
                    this.initializeSwitchValues();
                }
            },
        },
    },
    created() {
        this.initData();
    },
    mounted() {},
    methods: {
        handleFactor(modelOptions) {
            Object.keys(modelOptions).forEach((modelKey) => {
                if (modelOptions[modelKey].factor) {
                    this.localFactor[modelKey] = modelOptions[modelKey].factor;
                }
            });
        },
        handleMnvTabs(tabIndex, event) {
            if (event) {
                event.stopPropagation();
            }
            this.mnvTab = tabIndex;
        },
        isEmpty(input) {
            if (
                typeof input === "undefined" ||
                input === null ||
                input === "" ||
                input === "null" ||
                input.length === 0 ||
                (typeof input === "object" && !Object.keys(input).length)
            ) {
                return true;
            } else return false;
        },
        updateFactor(factor, key) {
            if (!this.localFactor[key]) {
                this.localFactor[key] = {};
            }
            this.localFactor[key] = factor;
        },
        isObject(value) {
            return typeof value === "object" && value !== null && !Array.isArray(value);
        },
        activeTabMnvReport() {
            this.$nextTick(() => {
                const container = this.$refs.analysisResult?.$el;
                if (container) {
                    container.scrollTop = 0;
                }
            });
        },
        changeGenerateMnvReportType(type) {
            this.reportMnvType = type;
        },
        showPropertyManagementModal() {
            this.$refs["SettingPanel"].showPropertyManagementModal();
        },
        handleAdjustData(data) {
            this.adjustData = data;
        },
        handleFixIndependData(data) {
            this.fixIndependData = data;
        },

        initializeSwitchValues() {
            this.switchValues = {
                mean: true, // 평균
                quartile: false, // 사분위수
                outlier: true, // 이상
                event: true, // 이벤트
            };
        },
        async showStatisticModal(event) {
            // 모달
            await event.stopPropagation();
            this.$refs.statisticModal.show();
        },
        async handleInfoPanel(event) {
            await event.stopPropagation();
            this.hideInfoPanel = !this.hideInfoPanel;
        },
        closeStatisticModal() {
            this.$refs.statisticModal.hide();
        },
        setData(modelIdx) {
            if (this.isEmpty(modelIdx)) {
                this.initData();
            } else {
                this.loadData(modelIdx);
            }
        },
        async initData() {
            this.detailInfo = {
                modelName: null,
                category: null,
                icon: null,
                analysisMethod: null,
                dispType: null,
                dispUnit: null,
                ptIdx: null,
                timeDsvn: null,
                fromDate: null,
                toDate: null,
                compareType: null,
                fromDateComp: null,
                toDateComp: null,
                enabled: "Y",
                description: null,
                modelContext: null,

                analysisAlgorithm: "LinearRegression",
                mnvMethod: null,

                variables: [],
                publish: null,
                grants: [],
                report: null,
                comments: [],
                userData: {
                    baseline: {},
                    report: {},
                },
                //MNV 분석데이터 저장용
                //모델컨텍스트 저장용
                modelOptions: {},
                //수정된 데이터 저장용
                varOptions: {},
            };
            this.resultData = [];
            this.$nextTick(() => {
                this.$refs["SettingPanel"].setNewMode();
            });
        },
        async loadData(modelIdx) {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    this.resultData = [];
                    await this.getAnalysisModel(modelIdx);
                    this.$emit("expand");
                } else return;
            } else {
                await this.getAnalysisModel(modelIdx);
                this.$emit("expand");
            }
        },
        async getAnalysisModel(modelIdx) {
            try {
                this.localFactor = {};
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    // this.detailInfo = result.data;
                    //저장된 모델을 가져온 후 해당모델에서도 userData를 사용할 수 있기위함
                    this.detailInfo = {
                        ...result.data,
                        userData: {
                            baseline: {},
                            report: {},
                        },
                    };
                    // MNV분석일 경우 modelOptions에 저장된 factor가 있을경우 localFactor에 저장 후 분석실행에서 사용한다
                    this.handleFactor(result.data.modelOptions);
                    // await this.$refs["SettingPanel"].setViewMode();

                    // await this.$refs["SettingPanel"].setDayTime(this.detailInfo);
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async notifyNewAnalModel() {
            const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
            if (result.value) return true;
            else return false;
        },
        async newAnalysisModel() {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    await this.initData();
                } else return;
            } else await this.initData();
        },
        clearData() {
            this.detailInfo = null;
            this.$forceUpdate();
        },
        changeAnalysisWayFunc(val) {
            this.analysisWay = val;
        },
        changeExpandStatus() {
            this.chartResize = !this.chartResize;
            this.$emit("expand");
        },
        exportToPDF() {
            this.isLoadingPdf = true;
            const resultElement = this.$refs.result;
            const TrendMethod = this.detailInfo.analysisMethod === "Trend";

            const elementsWithBoxShadow = resultElement.querySelectorAll("*");
            const originalStyles = Array.from(elementsWithBoxShadow).map((el) => ({
                element: el,
                boxShadow: el.style.boxShadow,
            }));
            elementsWithBoxShadow.forEach((el) => {
                el.style.boxShadow = "none";
            });

            const cardElements = resultElement.querySelectorAll(".MnvContainer .card,.MnvContainer .card-header");
            const originalBgColors = Array.from(cardElements).map((el) => ({
                element: el,
                backgroundColor: el.style.backgroundColor,
            }));
            cardElements.forEach((el) => {
                el.style.backgroundColor = "#ffffff";
            });

            if (TrendMethod) {
                resultElement.style.transform = "scaleY(0.8)";
                resultElement.style.transformOrigin = "top";
            }

            html2pdf()
                .set({
                    margin: TrendMethod ? 8 : 8,
                    filename: "document.pdf",
                    image: { type: "png", quality: 1 },
                    pagebreak: { mode: ["css"] },
                    html2canvas: {
                        scrollY: 0,
                        scrollX: 0,
                        scale: 1,
                        dpi: 300,
                        letterRendering: true,

                        ignoreElements: (element) =>
                            element.id === "pdf-button-area" ||
                            element.classList.contains("card-header-pills") ||
                            element.classList.contains("selectModelFomula"),
                    },
                    jsPDF: {
                        orientation: "landscape",
                        unit: "mm",
                        format: TrendMethod ? [420, 297] : "a3",
                        compressPDF: true,
                    },
                })
                .from(resultElement)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    if (TrendMethod) {
                        const totalPages = pdf.internal.getNumberOfPages();
                        if (totalPages > 1) {
                            pdf.deletePage(2);
                        }
                    }
                })
                .save()
                .finally(() => {
                    if (TrendMethod) {
                        resultElement.style.transform = "none";
                    }

                    originalStyles.forEach(({ element, boxShadow }) => {
                        element.style.boxShadow = boxShadow;
                    });

                    originalBgColors.forEach(({ element, backgroundColor }) => {
                        element.style.backgroundColor = backgroundColor;
                    });

                    this.isLoadingPdf = false;
                });
        },
        async saveData() {
            let complete;
            if (this.isEmpty(this.detailInfo.modelName)) {
                // 분석명을 입력 안했을 경우 설정 관리 모달 띄어줌 by woonsik
                this.$refs.SettingPanel.showSettingMgmtModal();
            } else {
                try {
                    let result = await backEndApi.analysisModel.saveAnalysisModel(this.detailInfo);
                    if (result.status == 200) {
                        complete = true;
                        await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                        this.$emit("refresh");
                        await this.getAnalysisModel(result.data.data.modelIdx);
                        this.clearResultData();
                    } else {
                        complete = false;
                        this.alertWarning(
                            `${result.data.message}`,
                            "",
                            `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            return complete;
        },
        async deleteAnalysisModel() {
            if (this.$refs["SettingPanel"].isNewMode) {
                this.alertNoti(popupMessages.ENERGY_ANALYSIS_NEW_INSERT_NOT_DELETE_MESSAGE);
                return;
            } else {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;
                else {
                    try {
                        let result = await backEndApi.analysisModel.deleteAnalysisModel(this.detailInfo.modelIdx);
                        if (result.status == 200) {
                            await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                            await this.initData();
                            await this.$emit("refresh");
                        } else {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                        }
                    } catch (e) {
                        console.erroe(e);
                    }
                }
            }
        },
        clearResultData() {
            this.resultData = [];
        },
        async getAnalysisResultData() {
            let analysisMethod = this.detailInfo.analysisMethod;

            const searchInfo = {
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate,

                variables: [],
                compareType: this.detailInfo.compareType,
                // fromDateComp: "",
                // toDateComp: "",
                datasetMode: "Divided",
                dispUnit: this.detailInfo.dispUnit,
            };

            this.detailInfo.variables.forEach((pt) => {
                searchInfo.variables.push(pt);
            });
            // fromDateComp 설정
            if (this.detailInfo.compareType === "User") {
                searchInfo.fromDateComp = this.detailInfo.fromDateComp;
            } else {
                searchInfo.fromDateComp = null;
            }

            // toDateComp 설정
            if (this.detailInfo.compareType === "User") {
                if (this.detailInfo.toDateComp) {
                    searchInfo.toDateComp = this.detailInfo.toDateComp;
                } else {
                    searchInfo.toDateComp = this.detailInfo.fromDateComp;
                }
            } else {
                searchInfo.toDateComp = null;
            }

            if (this.detailInfo.analysisMethod == "Goal") {
                const data = this.$refs["SettingPanel"].userVariableData;
                if (!this.isEmpty(data)) {
                    const transformedData = {};
                    data.forEach((item) => {
                        // 각 객체의 키를 순회
                        Object.keys(item).forEach((key) => {
                            if (!transformedData[key]) {
                                // 키가 없으면 초기화
                                transformedData[key] = [];
                            }
                            // 해당 키 배열에 값 추가
                            transformedData[key].push(item[key]);
                        });
                    });
                    searchInfo.userData = transformedData;
                } else searchInfo.userData = null;
            }

            // 에너지 캘린더 목록 조회
            if (analysisMethod == "Trend" || analysisMethod == "Diff" || analysisMethod == "Comp")
                await this.getEnergyCalendarList(searchInfo.fromDate, searchInfo.toDate);

            if (analysisMethod == "Trend") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.getAnalyzeTrend(searchInfo);
            } else if (analysisMethod == "Diff") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComparison(searchInfo);
            } else if (analysisMethod == "Comp") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComponent(searchInfo);
            } else if (analysisMethod == "Reltn") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                searchInfo["resultFormat"] = "Markdown";
                await this.analyzeCorrelation(searchInfo);
            } else if (analysisMethod == "Goal") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeGoal(searchInfo);
            } else if (analysisMethod == "Cost") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "MNV") {
                // const findMainVariable = this.detailInfo.variables.find((item) => item.roleType == "Depend");
                // const adjustData = this.$refs["SettingPanel"].adjustData;
                // const fixIndependData = this.$refs["SettingPanel"].fixIndependData;
                const mvnSearchInfo2 = {
                    startOfYear: 1,
                    ptIdx: this.detailInfo.ptIdx,
                    timeDsvn: this.detailInfo.timeDsvn,
                    fromDate: this.detailInfo.fromDate,
                    toDate: this.detailInfo.toDate,
                    variables: [],
                    compareType: this.detailInfo.compareType,
                    fromDateComp: this.detailInfo.fromDateComp,
                    toDateComp: this.detailInfo.toDateComp,
                    datasetMode: "Divided",
                    dispUnit: this.detailInfo.dispUnit,
                    userData: {
                        baseline: this.detailInfo.userData?.baseline,
                        report: this.detailInfo.userData?.report,
                    },
                    baseline: null,
                    report: null,
                };
                this.detailInfo.variables.forEach((pt) => {
                    mvnSearchInfo2.variables.push(pt);
                });

                if (Object.keys(this.adjustData).length > 0) {
                    Object.keys(this.adjustData).forEach((type) => {
                        mvnSearchInfo2[type] = this.adjustData[type];
                    });
                }
                if (!this.isEmpty(this.localFactor) && Array.isArray(mvnSearchInfo2.variables)) {
                    Object.keys(this.localFactor).forEach((factorKey) => {
                        // mvnSearchInfo2.variables 배열 중 name이 factorKey인 항목을 찾는다
                        const targetItem = mvnSearchInfo2.variables.find((item) => item.name === factorKey);

                        // 찾았다면 factor를 할당
                        if (targetItem) {
                            // factor 프로퍼티가 없을 경우를 대비해 초기화
                            if (!targetItem.factor) {
                                targetItem.factor = {};
                            }
                            // this.localFactor[factorKey]를 factor에 넣는다
                            targetItem.factor = this.localFactor[factorKey];
                        }
                    });
                }

                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeMNV(mvnSearchInfo2);
            } else {
                this.resultData = [];
                this.alertWarning("분석 결과를 조회할 수 없습니다.");
            }
        },
        // modelOptions에서 R2가 있음으로서 MNV 분석 데이터만 필터링하는 함수입니다.
        existModelContext(modelOptions) {
            if (!modelOptions || typeof modelOptions !== "object") return false;

            Object.keys(modelOptions).forEach((key) => {
                if (modelOptions[key] === null) {
                    delete modelOptions[key];
                }
            });

            if (Object.keys(modelOptions).length === 0) {
                return false;
            }

            const filtered = Object.fromEntries(
                Object.entries(modelOptions).filter(([, value]) => value && value.hasOwnProperty("R2"))
            );

            return filtered;
        },
        existServerData(adjustData) {
            // 1. adjustData가 존재하고 객체여야 함.
            if (!adjustData || typeof adjustData !== "object") {
                return false;
            }

            // 3. baseline과 report 프로퍼티가 있는지 확인 (없으면 false 반환)
            if (!("baseline" in adjustData) || !("report" in adjustData)) {
                return false;
            }

            let adjustObj = {};

            // 4. baseline 데이터 검증: null이 아니고, 객체 또는 배열이어야 함.
            if (
                adjustData.baseline &&
                (typeof adjustData.baseline === "object" || Array.isArray(adjustData.baseline))
            ) {
                adjustObj.baseline = adjustData.baseline;
            } else {
                adjustObj.baseline = {};
            }

            // 5. report 데이터 검증: null이 아니고, 객체 또는 배열이어야 함.
            if (adjustData.report && (typeof adjustData.report === "object" || Array.isArray(adjustData.report))) {
                adjustObj.report = adjustData.report;
            } else {
                adjustObj.report = {};
            }

            // 6. MNVData도 유효한지 검증 (이미 추가되어 있으므로)
            if (adjustData.MNVData && (typeof adjustData.MNVData === "object" || Array.isArray(adjustData.MNVData))) {
                adjustObj.MNVData = adjustData.MNVData;
            } else {
                adjustObj.MNVData = {};
            }

            // 7. baseline과 report가 모두 비어있는 경우 유효하지 않다고 판단
            const isEmptyData = (data) =>
                (Array.isArray(data) && data.length === 0) ||
                (typeof data === "object" && !Array.isArray(data) && Object.keys(data).length === 0);

            if (isEmptyData(adjustObj.baseline) && isEmptyData(adjustObj.report)) {
                return false;
            }

            return adjustObj;
        },
        async getAnalyzeTrend(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.getAnalyzeTrend(searchInfo);
                if (result.status == 200) {
                    this.apiCallErrorFlag = false;

                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.apiCallErrorFlag = true;

                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.apiCallErrorFlag = true;

                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComparison(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComparison(searchInfo);
                if (result.status == 200) {
                    this.apiCallErrorFlag = false;

                    this.resultData = result.data;
                } else {
                    this.apiCallErrorFlag = true;

                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.apiCallErrorFlag = true;

                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComponent(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComponent(searchInfo);
                if (result.status == 200) {
                    this.apiCallErrorFlag = false;

                    this.resultData = result.data;
                } else {
                    this.apiCallErrorFlag = true;

                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.apiCallErrorFlag = true;

                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeCorrelation(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeCorrelation(searchInfo);
                if (result.status == 200) {
                    this.apiCallErrorFlag = false;
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.apiCallErrorFlag = true;

                    if (this.isObject(result.data.detail)) {
                        this.resultData = result.data.detail;
                    }
                    // this.resultData = result.data.detail;
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.apiCallErrorFlag = true;

                console.error("correlation api call catch error", e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeGoal(searchInfo) {
            this.isLoading = true;

            try {
                let result = await backEndApi.analysisModel.analyzeGoal(searchInfo);
                if (result.status == 200) {
                    this.apiCallErrorFlag = false;

                    this.resultData = result.data;
                    if (result.data.analysisStatus == "CheckUserVariables") {
                        await this.alertWarning(
                            "사용자 정의 변수를 사용할 경우 변수 관리를 통해 사용자 정의 변수의 데이터를 정의 한 후 분석을 실행해주시기 바랍니다."
                        );
                        this.$refs["SettingPanel"].showPropertyManagementModal();
                        return;
                    } else this.isLoading = false;
                } else {
                    this.apiCallErrorFlag = true;

                    this.resultData = [];
                    this.isLoading = false;
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.apiCallErrorFlag = true;

                console.error(e);
            }
            // finally {
            //     this.isLoading = false;
            // }
        },
        async analyzeMNV(searchInfo) {
            this.isLoading = true;
            try {
                const hasModelContext = this.existModelContext(this.detailInfo.modelOptions);
                const hasAdjustData = this.existServerData(this.detailInfo.varOptions?.MNVData);

                // searchInfo를 복제하여 요청 객체를 생성합니다.
                let requestInfo = { ...searchInfo };
                // 서버측 조정 데이터가 있으면 baseline, report 값을 추가합니다.
                if (hasAdjustData) {
                    const { baseline, report } = this.detailInfo.varOptions.MNVData;
                    requestInfo = { ...requestInfo, baseline, report };
                }

                if (this.adjustData && Object.keys(this.adjustData).length > 0) {
                    requestInfo = { ...requestInfo, ...this.adjustData };
                }

                // 첫번째 API 호출: 조정 데이터가 반영된 상태로 호출합니다.
                const result = await backEndApi.analysisModel.analyzeMNV(requestInfo);

                // 모델 컨텍스트가 존재하면 이를 추가한 후 확장된 결과를 받아옵니다.
                let extendedResult = null;
                if (hasModelContext) {
                    requestInfo = { ...requestInfo, modelContext: this.detailInfo.modelOptions };
                    extendedResult = await backEndApi.analysisModel.analyzeMNV(requestInfo);
                }

                // API 호출이 성공적이면 결과를 처리합니다.
                if (result.status === 200) {
                    this.apiCallErrorFlag = false;

                    this.resultData = result.data;
                    this.existMnvReportFlag = hasModelContext ? true : false;
                    // 확장 데이터(모델 컨텍스트 관련)가 있다면 필요한 필드를 병합합니다.
                    if (extendedResult) {
                        const {
                            model: selectedModel,
                            analysisOverview,
                            analysisStatus,
                            data,
                            stats,
                        } = extendedResult.data;
                        Object.assign(this.resultData, {
                            selectedModel,
                            analysisOverview,
                            analysisStatus,
                            data,
                            stats,
                        });
                    }
                } else {
                    this.apiCallErrorFlag = true;

                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (error) {
                this.apiCallErrorFlag = true;

                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        // 에너지 캘린더 목록 조회
        async getEnergyCalendarList(fromDate, toDate) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.getEnergyCalendarList(fromDate, toDate);
                if (result.status == 200) {
                    this.calendarList = result.data;
                    if (!this.isEmpty(this.calendarList)) {
                        this.calendarList.forEach((calendar) => {
                            calendar.regDate = moment(calendar.regDate).format("YYYY-MM-DD HH:mm:ss");
                            calendar.modDate = moment(calendar.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    }
                } else this.calendarList = [];
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        // 에너지캘린더 조회 (param: id)
        async getEnergyCalendar() {},
        // 에너지캘린더 저장 (param: calendar)
        async saveEnergyCalendar() {},
        // 에너지캘린더 삭제 (param: id)
        async deleteEnergyCalendar() {},
        showAnalysisHistory() {
            this.$refs["SettingPanel"].showAnalysisHistory();
        },
    },
};
</script>

<style scoped>
.analysisResultClass {
    margin-right: 15px;
}
.mnvTab {
    margin: 0 5px;
    padding: 2px 4px;
    border: none;
    background-color: white;
    color: fff;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
}

.mnvTab:hover {
    background-color: #42a5f5;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}

.mnvTab.active,
.mnvTab:focus,
.mnvTab:active {
    background-color: #007bff;
    color: white;
    outline: none;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.4);
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(235, 235, 235, 0.4);
    z-index: 9999;
    transition: background-color 0.9s ease;
}
.analysisSpinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-white {
    background-color: #ffffff;
    color: #000000;
    border: none;
}

.btn-white:hover {
    background-color: #cecece;
    color: #000000;
}
.row {
    width: 100%;
    margin: 0px !important;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-size: 11px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-control {
    display: flex;
    justify-content: flex-end;
}

.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

.accordion-title {
    background-color: #ececec;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: solid #ececec 1px;
    border-radius: 6px 6px 0px 0px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.setting-itemview {
    /* height: 83%; */
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.result-itemview {
    /* height: 83%; */
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.list-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 68px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}
</style>
